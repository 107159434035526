/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    span: "span",
    math: "math",
    semantics: "semantics",
    mrow: "mrow",
    mi: "mi",
    mo: "mo",
    msup: "msup",
    mn: "mn",
    annotation: "annotation"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Estoy haciendo pruebas para poder escribir ecuaciones en el blog. Primero intenté con MathML, una forma de escribir en lenguaje matemático usando XML, podemos escribir con este lenguaje en una web y se mostrará la ecuación siempre que nuestro navegador lo soporte, recomiendo usar Firefox que lo implementa desde tiempos inmemoriales o Chrome que lo implementó en su versión 24. Desgraciadamente wordpress.com no lo soporta aunque sería la mejor opción puesto que con este lenguaje es posible especificar que partes de la ecuación son las variables, las funciones o los operadores. Pero ya que no lo puedo utilizar aquí he decidido usar ", React.createElement(_components.a, {
    href: "http://en.support.wordpress.com/latex/",
    title: "LaTeX en wordpress.com"
  }, "LaTeX"), ". Como ejemplo he decidido mostrar la Transformada de Laplace que es, junto con la Transformada Z, la herramienta matemática en que se basa la Ingeniería de Control."), "\n", React.createElement(_components.p, null, React.createElement(_components.span, {
    className: "math math-inline"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "m"), React.createElement(_components.mi, null, "a"), React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "h"), React.createElement(_components.mi, null, "c"), React.createElement(_components.mi, null, "a"), React.createElement(_components.mi, null, "l"), React.createElement(_components.mi, null, "L"), React.createElement(_components.mi, null, "l"), React.createElement(_components.mi, null, "e"), React.createElement(_components.mi, null, "f"), React.createElement(_components.mi, null, "t"), React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "f"), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.mi, null, "t"), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")"), React.createElement(_components.mi, null, "r"), React.createElement(_components.mi, null, "i"), React.createElement(_components.mi, null, "g"), React.createElement(_components.mi, null, "h"), React.createElement(_components.mi, null, "t")), React.createElement(_components.mo, null, "="), React.createElement(_components.mi, null, "i"), React.createElement(_components.mi, null, "n"), React.createElement(_components.msup, null, React.createElement(_components.mi, null, "t"), React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "i"), React.createElement(_components.mi, null, "n"), React.createElement(_components.mi, null, "f"), React.createElement(_components.mi, null, "t"), React.createElement(_components.mi, null, "y"))), React.createElement(_components.mi, {
    mathvariant: "normal"
  }, "_"), React.createElement(_components.mn, null, "0"), React.createElement(_components.msup, null, React.createElement(_components.mi, null, "e"), React.createElement(_components.mrow, null, React.createElement(_components.mo, null, "−"), React.createElement(_components.mi, null, "s"), React.createElement(_components.mi, null, "t"))), React.createElement(_components.mi, null, "f"), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.mi, null, "t"), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")"), React.createElement(_components.mi, null, "d"), React.createElement(_components.mi, null, "t")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "mathcal{L}left{f(t)right}=int^{infty}\\_{0}e^{-st}f(t) dt")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "m"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "a"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "h"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "c"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "a"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.01968em"
    }
  }, "l"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "L")), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.01968em"
    }
  }, "l"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "e"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.10764em"
    }
  }, "f"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.10764em"
    }
  }, "f"), React.createElement(_components.span, {
    className: "mopen"
  }, "("), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mclose"
  }, ")"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.02778em"
    }
  }, "r"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "i"), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.03588em"
    }
  }, "g"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "h"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t")), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2777777777777778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2777777777777778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1.1591079999999998em",
      verticalAlign: "-0.31em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "i"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "n"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.8491079999999999em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-3.063em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "i"), React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "n"), React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.10764em"
    }
  }, "f"), React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "t"), React.createElement(_components.span, {
    className: "mord mathnormal mtight",
    style: {
      marginRight: "0.03588em"
    }
  }, "y"))))))))), React.createElement(_components.span, {
    className: "mord",
    style: {
      marginRight: "0.02778em"
    }
  }, "_"), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord"
  }, "0")), React.createElement(_components.span, {
    className: "mord"
  }, React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "e"), React.createElement(_components.span, {
    className: "msupsub"
  }, React.createElement(_components.span, {
    className: "vlist-t"
  }, React.createElement(_components.span, {
    className: "vlist-r"
  }, React.createElement(_components.span, {
    className: "vlist",
    style: {
      height: "0.7935559999999999em"
    }
  }, React.createElement(_components.span, {
    style: {
      top: "-3.063em",
      marginRight: "0.05em"
    }
  }, React.createElement(_components.span, {
    className: "pstrut",
    style: {
      height: "2.7em"
    }
  }), React.createElement(_components.span, {
    className: "sizing reset-size6 size3 mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, React.createElement(_components.span, {
    className: "mord mtight"
  }, "−"), React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "s"), React.createElement(_components.span, {
    className: "mord mathnormal mtight"
  }, "t"))))))))), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.10764em"
    }
  }, "f"), React.createElement(_components.span, {
    className: "mopen"
  }, "("), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t"), React.createElement(_components.span, {
    className: "mclose"
  }, ")"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "d"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "t")))))), "\n", React.createElement(_components.p, null, "Para escribir rápidamente la ecuación ya que no se latex he utilizado el programa ", React.createElement(_components.a, {
    href: "http://equalx.sourceforge.net/",
    title: "EqualX LaTeX Equation Editor"
  }, "EqualX"), ", muy sencillo de utilizar y exactamente lo que necesitaba."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
